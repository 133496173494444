import React from 'react';
import { Link, graphql } from "gatsby"
import "../css/@wordpress/block-library/build-style/style.css"
import "../css/@wordpress/block-library/build-style/theme.css"
import Layout from "../components/layout"
import Seo from "../components/seo"

  

const Fires = ({ data, location }) => {
    const siteTitle = data.site.siteMetadata.title
  return (
    <Layout location={location} tigitle={siteTitle}>
    <Seo title="Fires de Llibres" />
    <article
      className="global-wrapper blog-post"
      itemScope
      itemType="http://schema.org/Article"
    >
    <h1 itemProp="headline">Fires de Llibres</h1>
        <section itemProp="articleBody">
        <p>Cada any a Catalunya s'organitzen diverses fires que han estat esdeveniments destacats per la reproducció de gravats antics o moderns, encarregats a artistes de prestigi reconegut. La col·lecció de cartells d'aquestes fires és una veritable antologia de l'art contemporani català.</p>
        <p>Per a la realització dels discursos en aquestes fires, s'ha sol·licitat la col·laboració d'escriptors, pintors, dibuixants, periodistes, cantants i personalitats de la cultura catalana, i també s'ha promogut i finançat el Monument al llibre de Joan Brossa.</p>
      <ul>
        <li>Fira del Llibre d'Ocasió Antic i Modern</li>
        <li>Mercat dels llibres prohibits</li>
        <li>Fira del Paper i Col·leccionisme</li>
        <li>Mercat del Llibre Vell de Poesia de Folgueroles</li>
        <li>Saló del Col·leccionisme</li>
        <li>Fira del Llibre Vell de Figueres</li>
        <li>Tortosantic 2018</li>
        <li>Fira del Llibre de Muntanya Vic-Collsacabra</li>
        <li>XIX Salón de Libro antiguo de Madrid</li>
        <li>X Fira Internacional del Llibre Antic</li>
        <li>Saló Internacional del Còmic de Barcelona</li>
      </ul>
      <p>Aquestes fires solen incloure la venda d'obres literàries editades en diferents èpoques i cultures que han patit censura.</p>
        </section>
    </article>
    </Layout>
  )
}

export default Fires

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
